
<script setup>
  import {RouterView, RouterLink} from "vue-router"
</script>

<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" 
      app
      :image="require('@/assets/images/sidebar2.jpg')">
          <v-list-item 
            title= "Nigeria 2023" 
            subtitle="General Elections"
            prepend-icon= "mdi-poll" 
            color="green-darken-5"
            >
          </v-list-item>
      <v-divider></v-divider>
      <v-list>
        
           <v-list-item

          v-for="item in items"
          :title="item.title"
          :prepend-icon="item.icon"
          :to="item.route"
          link
        >
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-app-bar color="blue-grey-darken-2" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Election Monitoring System</v-toolbar-title>
    </v-app-bar>

    <v-main >
      <RouterView 
        :party="party" 
        :statelist="statelist" 
        :states="states"
        :ward="ward"
        :lga="lga"
        :polling_unit="polling_unit"
        :zone="zone"
        />
    </v-main>
  </v-app>
</template>

<script>
var lga = require('./views/database/lga.json')
var party = require('./views/database/party.json')
var ward = require('./views/database/ward.json')
var statelist = require('./views/database/state.json')
var polling_unit = require('./views/database/polling_unit.json')
var zone = require('./views/database/zone.json')
//var result = require('./views/database/result.json')
//var result = require('http://ec2-34-206-153-143.compute-1.amazonaws.com/api/V1/project-watch/official_results')



function createValueList(list){
  let result = list.map(({ name }) => name)
  return(result)
}
var states = createValueList(statelist)

  export default {
    data: () => ({ 
      drawer: null,
      ward: ward,
      statelist,
      states,
      polling_unit,
      party,
      zone,
      items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/' },
          { title: 'States', icon: 'mdi-map-marker', route: '/' },
          { title: 'Presidential', icon: 'mdi-account-circle-outline', route: '/'  },
          { title: 'Senate', icon: 'mdi-account-circle-outline',route: '/'  },
          { title: 'House of Assembly', icon: 'mdi-account-circle-outline',route: '/'  },
          { title: 'Collation', icon: 'mdi-vote',route: '/'  },
          { title: 'About', icon: 'mdi-help-box',route: '/'  },
        ],
      
      
      
     }),
  }
</script>