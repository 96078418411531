

<script >


export default {

    data() {
        return {
            totalResults: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            top4: [0,1,2,3],

            stateResults: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            top10State: [0,1,2,3,4,5,6,7,8,9],
            result: [],

           
        }
    },
    props: [
        'statelist','states', 'party', 'ward', 'lga', 'polling_unit', 'zone'
    ],
    
    methods: {
        showStateResults: function(){
            document.getElementById('focalState').innerText = this.selectedState+" STATE RESULTS"
            this.displayStateData()
        },

        displayStateData: function(){
            //get lgas for the selected state
            var selectedStateId = this.statelist.find(el => el.name === this.selectedState)
            //alert(selectedStateId.id)
            var selectedLga = this.lga.filter(function(o) { return o.state ===selectedStateId.id ; })
            var selectedZone= this.zone[(selectedLga[0].zone)-1].name
            var selectedWard = this.ward.filter(function(o) { return o.state ===selectedStateId.id ; })
            var selectedPollingUnit = this.polling_unit.filter(function(o) { return o.state ===selectedStateId.id ; })

            document.getElementById('summary_panel').innerHTML="STATE: "+this.selectedState+"<br>"
            document.getElementById('summary_panel').innerHTML+="ZONE: "+selectedZone+"<br>"
            document.getElementById('summary_panel').innerHTML+="TOTAL LGA(s): "+selectedLga.length+"<br>"
            document.getElementById('summary_panel').innerHTML+="TOTAL WARD(s): "+selectedWard.length+"<br>"
            document.getElementById('summary_panel').innerHTML+="TOTAL POLLING UNITS(s): "+selectedPollingUnit.length+"<br>"
            document.getElementById('summary_panel').innerHTML+="TOTAL VOTES: <br>"

            //document.getElementById('summary_panel').innerHTML = JSON.stringify(selectedLga)

            //compute result temporary call
            this.computeStateResults()
        },

        computeResults: function(){
            
            this.totalResults=[]
            for (let i=0; i<18; i++){
                var partyResult = this.result.filter(function(o) { return o.party ===i+1 ; })
                let score = partyResult.map(({ score }) => score)
                let totalScore = 0
                try{
                    totalScore = score.reduce(function(a, b){
                        return a + b;
                    });
                }catch(err){
                    console.log(err)
                }
                
                this.totalResults.push(totalScore)
            }
            this.top4 = [...this.totalResults]
            this.top4 = this.top4.sort(function(a, b){return a - b})
            this.top4.reverse()

            for (let i=0; i<this.top4.length; i++){
                this.top4[i]=this.totalResults.indexOf(this.top4[i])
            }
            //alert(this.top4)
            //alert(this.totalResults)
        },
        computeStateResults: function(){
            
            //alert(JSON.parse(document.getElementById('data_store').innerHTML))
            var currentResult = JSON.parse(document.getElementById('data_store').innerHTML);
            this.result = currentResult
            this.computeResults()
            this.stateResults=[]
            var selectedStateId = this.statelist.find(el => el.name === this.selectedState)

            //selectedStateId.state_id = 38
            var stateR = this.result.filter(function(o) { return  o.state === selectedStateId.id ; })

            //alert(JSON.stringify(stateR))
            for (let i=0; i<18; i++){
                var partyResult = stateR.filter(function(o) { return o.party ===i+1 ; })
                let score = partyResult.map(({ score }) => score)
                let totalScore = 0
                try{
                    totalScore = score.reduce(function(a, b){
                        return a + b;
                    });
                }catch(err){
                    console.log(err)
                }
                
                this.stateResults.push(totalScore)
            }
            this.top10State = [...this.stateResults]
            this.top10State = this.top10State.sort(function(a, b){return a - b})
            this.top10State.reverse()

            for (let i=0; i<this.top10State.length; i++){
                this.top10State[i]=this.stateResults.indexOf(this.top10State[i])
            }
            //alert(this.top10State)
           // alert(this.stateResults)
        },
        firstComputeStateResults: function(){
            
            //alert(JSON.parse(document.getElementById('data_store').innerHTML))
            var currentResult = JSON.parse(document.getElementById('data_store').innerHTML);
            this.result = currentResult
            this.computeResults()
            this.stateResults=[]
            var selectedStateId = this.statelist.find(el => el.name === 1)

            //selectedStateId.state_id = 38
            var stateR = this.result.filter(function(o) { return  o.state === 1 ; })

            //alert(JSON.stringify(stateR))
            for (let i=0; i<18; i++){
                var partyResult = stateR.filter(function(o) { return o.party ===i+1 ; })
                let score = partyResult.map(({ score }) => score)
                let totalScore = 0
                try{
                    totalScore = score.reduce(function(a, b){
                        return a + b;
                    });
                }catch(err){
                    console.log(err)
                }
                
                this.stateResults.push(totalScore)
            }
            this.top10State = [...this.stateResults]
            this.top10State = this.top10State.sort(function(a, b){return a - b})
            this.top10State.reverse()

            for (let i=0; i<this.top10State.length; i++){
                this.top10State[i]=this.stateResults.indexOf(this.top10State[i])
            }
            //alert(this.top10State)
           // alert(this.stateResults)
        },
        arraySum: function(arr){
            try{
                   var arrayTotal = arr.reduce(function(a, b){
                        return a + b;
                    });
                }catch(err){
                    console.log(err)
                }
                return arrayTotal
        },
        percentageCalculator: function(n,d){
            let p = n/d*100
            p = p.toFixed(2)
            return p
        },

        
        reloadData: function(){
            let xhttp = new XMLHttpRequest();
            const interval = setInterval(function() {
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200 )  {
                    var tags = JSON.parse(this.responseText);
                    try{
                        document.getElementById('data_store').innerHTML = JSON.stringify(tags.results)
                    }catch{
                        
                    }
                  
                    //console.log(tags.results);
                    
                    }
                };
                //xhttp.open("GET", "http://ec2-34-206-153-143.compute-1.amazonaws.com/api/V1/project-watch/official_results", true);
                xhttp.open('GET','https://server-elections.projectwatch.com.ng/api/V1/project-watch/official_results', true)
                xhttp.send();
                
                
                const date = new Date();
                document.getElementById('update').innerHTML = 'updated at: '+date

                //this.result = JSON.parse(document.getElementById('data_store').innerHTML)
                
                
            }, 10000);
           
            
           
            
        },

        
        
    },

    
 beforeMount() {
   this.reloadData()
   this.computeResults()
   
},
}



</script>
<style>
.card_background {
    background-color: transparent;
    color: white;
    width: 100%;

}



.stat {
    text-align: center;
    color: rgb(65, 63, 63);
    background-color: rgb(239, 245, 238);
}

.candidate_avater img {
    border-radius: 50%;
    border: double 2px white;
    background-color: white;
    width: 100px;

}

.dashboard {
    background-image:
        linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)),
        url("./nigeria.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;

}
</style>
<template>
    <div>
        <v-container class="dashboard">
            <h2>Nigerian 2023 Election</h2>
            <p>Official Results </p>
            <font id="update" style="font-size: xx-small; color: greenyellow;">('live update')</font>
            <v-divider></v-divider>

            <v-row no-gutters>
                <v-col v-for="idx in this.top4.slice(0,4)" :key="idx" cols="12" sm="3">
                    <v-container class="ma-2 pa-6 card_background center">
                        <div style="height: 100px; margin-bottom: 10px;">
                            <v-img class="candidate_avater" :src="'./party_logos/' + party[idx].pix"></v-img>
                        </div>
                        <v-label class="text-h6 text-wrap">{{ party[idx].candidate }}</v-label>
                        <v-card-text style="font-size:large; ">{{ party[idx].sname }}</v-card-text>
                        <v-progress-linear :model-value="percentageCalculator(totalResults[idx], arraySum(totalResults) )" color="light-blue" height="10"></v-progress-linear>
                        <v-card-text class="text-h5" color="light-blue">{{ percentageCalculator(totalResults[idx], arraySum(totalResults) )}}%</v-card-text>
                        
                        <v-card-text class="font-weight-light"  >{{totalResults[idx]}}</v-card-text>
                        <br>
                        <v-card-text class="text-caption"  >States > 25% : -</v-card-text>
                    </v-container>
                </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row no-gutters >
                <v-col cols="6" sm="3"  >
                    <v-container class="ma-2 pa-6">
                        <p class="text-h6">{{polling_unit.length.toLocaleString("en-US")}}</p>
                        <v-card-text class="font-weight-light">Polling Units</v-card-text>
                    </v-container>
                </v-col>
                <v-col cols="6" sm="3"  >
                    <v-container class="ma-2 pa-6">
                        <p class="text-h6">{{ward.length.toLocaleString("en-US")}}</p>
                        <v-card-text class="font-weight-light">Wards registered</v-card-text>
                    </v-container>
                </v-col>

                <v-col cols="6" sm="3"  >
                    <v-container class="ma-2 pa-6">
                        <p class="text-h6">{{zone.length.toLocaleString("en-US")}}</p>
                        <v-card-text class="font-weight-light">Zones</v-card-text>
                    </v-container>
                </v-col>

                <v-col cols="6" sm="3"  >
                    <v-container class="ma-2 pa-6">
                        <p class="text-h6">{{party.length}}</p>
                        <v-card-text class=" text-body-1">Political parties</v-card-text>
                    </v-container>
                </v-col>

            </v-row>

        </v-container>

        <v-container>
            <v-row no-gutters>
                <v-col cols="12" sm="6" class="pa-6 ">

                    <div>
                        <v-select
                            label="Select State to view result"  
                            :items= states
                            item-text="text"
                            return-object
                            v-model="selectedState"
                            @update:modelValue="showStateResults"
                        >
                        </v-select>
                        
                    </div>

                    <div class="stat mt-2 " >
                        <h2>Summary Statistics</h2>
                        <div id="summary_panel">
                            

                        </div>
                        <div id="data_store" style="display: none;"></div>
                    </div>
                </v-col>


                <v-col cols="12" sm="6" class="pa-6">
                    <h2 id="focalState">{{ Object.values(statelist)[0]['name'] }} State Results</h2>
                    <div v-for="idx in top10State.slice(0,10)" :key="idx">
                        <v-row no-gutters>
                            <v-col cols="2">
                                <v-img :src="'./party_logos/' + party[idx].pix " height="40px" width="40px" cover
                                    style="border-radius: 50%;"></v-img>
                            </v-col>
                            <span style="font-size: smaller;"> {{ party[idx].sname }} : {{ percentageCalculator(stateResults[idx], arraySum(stateResults) )}}%</span>
                        </v-row>

                    </div>

                </v-col>
            </v-row>
        </v-container>
    </div>

</template>


<style>
.btn {
    width: 100%;
}
</style>